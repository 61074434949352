<template>
  <div class="insert-season-sale-invoice">

    <vs-row class="products-fields">
      <vs-col style="height: 100%">
        <draggable-dynamic-table ref="seasonSaleInvoiceList"
                                 v-if="data.length"
                                 :in-modal="true"
                                 :active-grid="true"
                                 :fix-screen="true"
                                 :options="options"
                                 :columns="columnsLabel"
                                 @row:deleted="handleDeleteRow"
                                 v-model="data"/>
      </vs-col>
    </vs-row><div class="general-fields">
    <vs-col class="md:pr-2 md:w-1/3">
      <custom-validate-input :label="$t('sales.seasonSchedules.insert.labels.totalQuantity')"
                             :styles="{width: '100%'}"
                             :disabled="true"
                             :value="{value: getTotalQuantity, isValid: true}"/>
    </vs-col>

    <!--      <vs-col class="md:pr-2 md:w-1/4">-->
    <!--        <custom-price-input :label="$t('sales.seasonSchedules.insert.labels.totalPrice')"-->
    <!--                            :disabled="true"-->
    <!--                            :value="{value: getTotalPrice, isValid: true}"/>-->
    <!--      </vs-col>-->

    <vs-col class="md:pr-2 md:w-1/3">
      <custom-price-input :label="$t('sales.seasonSchedules.insert.labels.totalDiscount')"
                          :disabled="true"
                          :value="{value: getTotalDiscount, isValid: true}"/>
    </vs-col>

    <vs-col class="md:w-1/3">
      <custom-price-input :label="$t('sales.seasonSchedules.insert.labels.finalPrice')"
                          :disabled="true"
                          :value="{value: getFinalPrice, isValid: true}"/>
    </vs-col>
  </div>


    <!-- receive from customer -->
    <vs-prompt
      color="danger"
      :buttons-hidden="true"
      title=""
      @close="receiveFromCustomerPromptStatus = true"
      :active.sync="receiveFromCustomerPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.receive.insert.dynamicTitle', {name: newInvoice.user.name.value}) }}
          </vs-col>

          <vs-spacer/>
        </vs-row>
      </div>

      <div class="prompt-content p-3">
        <insert-fast-receive :user="newInvoice.user" :price="userDebt"
                             :subject="newInvoice.id.value ? $t('sales.seasonSchedules.receive.dynamicTitle', {id: newInvoice.id.value}) : $t('sales.seasonSchedules.receive.title')"/>

        <div class="action-buttons">
          <button class="payment" @click="handleClick('fastReceiveBTN')">{{
            $t('sales.seasonSchedules.receive.labels.receiveAndReserve') }}
          </button>

          <button @click="routeToInvoice" class="cancel">{{ $t('sales.seasonSchedules.receive.labels.cancel') }}</button>
        </div>
      </div>
    </vs-prompt>
    <!-- /receive from customer -->

    <vs-button id="showSeasonPaymentModal" class="useral-action-button" @click="handleShowPayment"/>
    <!--    <vs-button id="insertSaleInvoiceBTN" class="useral-action-button" @click="sendData"/>-->
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment-jalaali'
  import {getUser, getUsers, getUserSaleAddons} from '@/http/requests/users/users'
  import CustomSelect from '@/components/customSelect/customSelect'
  import SelectUsers from '@/views/admin/customers/select/selectUsers'
  import CustomNumberInput from '@/components/customInput/customNumberInput'
  import CustomValidateInput from '@/components/customInput/customValidateInput'
  import SuggestBox from '@/components/draggableDynamicTable/suggestBox'
  import InsertUser from '@/views/admin/customers/insert/insertUser'
  import CustomIcon from '@/components/customIcon/customIcon'
  import InsertFastReceive from '../../../treasury/receive/insert/insertFastReceive'
  import {insertSaleInvoice} from '../../../../../http/requests/sales'
  import {
    addComma,
    checkUserPermissions,
    getTimeFromServer,
    hideLoading,
    showLoading
  } from "../../../../../assets/js/functions";
  import SelectSaleAddons from "../../invoices/insert/selectSaleAddons";
  import {getActiveDiscountGroup} from "../../../../../http/requests/discountGroups";
  import CustomPriceInput from "../../../../../components/customInput/customPriceInput";
  import {insertSeasonSchedule} from "../../../../../http/requests/seasonSchedules";

  export default {
    name: 'insertSeasonScheduleInvoice',
    components: {
      CustomPriceInput,
      SelectSaleAddons,
      InsertFastReceive,
      CustomIcon,
      InsertUser,
      SuggestBox,
      CustomValidateInput,
      CustomNumberInput,
      SelectUsers,
      CustomSelect,
    },
    props: {
      selectedTimes: [],
      reserveInfo: {},
      userInfo: {},
      courtsList: []
    },
    data() {
      return {
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'delete',
            i18n: 'sales.seasonSchedules.insert.table.header.delete',
            width: '70px',
            minWidth: 70,
            /*sortable: true,*/
            actions: true,
            showAction: 'always',
            action: {
              color: 'danger',
              icon: 'icon-x',
              iconPack: 'feather',
              type: 'button'
            },
            event: 'row:deleted'
          },
          {
            field: 'finalPrice',
            i18n: 'sales.seasonSchedules.insert.table.header.finalPrice',
            width: 'calc(100% / 4)',
            minWidth: 120,
            /*sortable: true,*/
            /*editable: true,*/
          },
          {
            field: 'discount',
            i18n: 'sales.seasonSchedules.insert.table.header.discount',
            width: 'calc(100% / 4)',
            minWidth: 120,
            /*sortable: true,*/
            /*editable: true,*/
          },
          {
            field: 'totalPrice',
            i18n: 'sales.seasonSchedules.insert.table.header.totalPrice',
            width: 'calc(100% / 4)',
            minWidth: 120,
            /*sortable: true,*/
            /*editable: true,*/
          },
          {
            field: 'quantity',
            i18n: 'sales.seasonSchedules.insert.table.header.quantity',
            width: 'calc(100% / 4)',
            minWidth: 80,
          },
          {
            field: 'court',
            i18n: 'sales.seasonSchedules.insert.table.header.court',
            align: 'center',
            width: 'calc((100% / 4) / 2)',
            minWidth: 150,
            footer: {}
          },
          {
            field: 'rowNumber',
            i18n: 'sales.seasonSchedules.insert.table.header.row',
            align: 'center',
            width: '50px',
            minWidth: 50,
            autoIncrement: true,
            footer: {
              type: 'auto-counter'
            }
          }
        ],
        insertInvoiceRequestSent: false,
        firstVisit: true,
        firstVisitTimer: 0,
        isTypingTimer: 0,
        usersLoadingTimer: 0,
        users: [],
        data: [],
        selectedOrders: [],
        selectedIndex: 0,
        getSaleAddonStatus: false,
        total_count: 0,
        page: 0,
        filters: [],
        suggests: {
          name: []
        },
        suggestPromptStatus: false,
        suggestUserPromptStatus: false,
        newSuggestUserPromptStatus: false,
        suggestUserAddressPromptStatus: false,
        newSuggestUserAddressPromptStatus: false,
        selectSaleAddonPromptStatus: false,
        receiveFromCustomerPromptStatus: false,
        maxDate: moment(getTimeFromServer()).format(this.$validator('moment.date')),
        newInvoice: {
          id: {
            value: '',
            isValid: true
          },
          actionType: 0,
          status: {},
          saleAddons: [],
          user: {
            id: 0,
            name: {
              value: '',
              isValid: true
            },
            characterId: 0,
          },
          address: {
            details: {
              value: '',
              isValid: true
            },
            address: {}
          },
          date: {
            value: moment(getTimeFromServer()).format(this.$validator('moment.date')),
            isValid: true
          },
          finalPrice: 0,
          totalPrice: {
            value: '',
            isValid: true
          },
          totalDiscount: {
            value: '',
            isValid: true
          },
        },
        activeDiscounts: [],
        userDebt: 0,
        reserveGeneratedInfo: {},
      }
    },
    computed: {
      getFinalPrice() {
        return this.getTotalPrice - this.getTotalDiscount
      },

      getTotalPrice () {
        let totalPrice = 0
        this.data.forEach(item => {
          totalPrice += item.totalPrice.value
        })

        return totalPrice
      },

      getTotalDiscount () {
        let totalDiscount = 0
        this.data.forEach(item => {
          totalDiscount += item.discount.value
        })

        return totalDiscount
      },

      getTotalQuantity () {
        let totalQuantity = 0
        this.data.forEach(item => {
          totalQuantity += item.quantity
        })

        return totalQuantity
      }
    },
    created() {
      // if (!checkUserPermissions('invoice.set_discount')) {
      //   const discountIndex = this.columnsLabel.map(e => e.field).indexOf('discount')
      //   if (discountIndex > -1) {
      //     this.columnsLabel[discountIndex].editable = false
      //   }
      // }

      this.getUserDefault()
      this.selectedOrders = this.selectedTimes
      this.reserveGeneratedInfo = this.reserveInfo
      this.getReserves()
    },
    methods: {
      getUserDefault() {
        this.newInvoice.user.id = this.userInfo.id
        this.newInvoice.user.name.value = this.userInfo.name.value
      },
      getUserDiscounts() {
        getActiveDiscountGroup(this.newInvoice.user.id).then(response => {
          // this.activeDiscounts = response.data.data
          const discounts = response.data.data
          this.activeDiscounts = []
          discounts.forEach(discount => {
            this.activeDiscounts.push({
              termId: discount.term.id,
              price: discount.price,
              percent: discount.percent
            })
          })

          this.getUserSaleAddons()
        })
          .catch(error => {
            hideLoading()
            switch (error.response.status) {
              case 400:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('sales.invoices.notifications.userGroup', {name: this.newInvoice.user.name.value}),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('sales.invoices.notifications.discountsError'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          })
      },
      getUserSaleAddons() {
        this.getSaleAddonStatus = false
        getUserSaleAddons(this.newInvoice.user.id).then(response => {
          this.newInvoice.saleAddons = []
          const saleAddons = response.data.data
          if (saleAddons && saleAddons.length > 0) {
            saleAddons.forEach(saleAddon => {
              if (saleAddon.required) {
                this.newInvoice.saleAddons.push({
                  rowNumber: this.newInvoice.saleAddons.length + 1,
                  id: saleAddon.id,
                  court: saleAddon.name,
                  quantity: 1,
                  maxSelect: saleAddon.max_select,
                  unitPrice: {
                    value: saleAddon.price,
                    type: 'price'
                  },
                  finalPrice: {
                    type: 'price',
                    value: saleAddon.price.toString()
                  },
                  required: saleAddon.required
                })
              }
            })

            setTimeout(() => {
              for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].saleAddonsList.length === 0) {
                  this.selectedIndex = i
                  this.handleNewSaleAddonInserted(this.newInvoice.saleAddons)
                }
              }
            }, 50)
            this.getSaleAddonStatus = true
          } else {
            for (let i = 0; i < this.data.length; i++) {
              setTimeout(() => {
                this.selectedIndex = i
                this.handleNewSaleAddonInserted([])
              }, 10)
            }
          }
          hideLoading()
          this.data = []
          this.getReserves()
        })
          .catch(error => {
            hideLoading()
            this.getSaleAddonStatus = true
          })
      },
      getReserves() {
        // get reserves for suggests
        const reserves = this.selectedOrders
        let totalPrice = 0
        reserves.forEach((reserve) => {
          const courtIndex = this.courtsList.map(e => e.name).indexOf(reserve.name)
          const data = {
            id: this.data.length + 1,
            court: reserve.name || '',
            quantity: reserve.time_count,
            totalPrice: {
              value: reserve.total_price,
              type: 'price'
            },
            discount: {
              value: reserve.discount,
              type: 'price'
            },
            finalPrice: {
              value: reserve.final_price,
              type: 'price'
            },
            courtId: this.courtsList[courtIndex].id
          }
          this.data.push(data)
        })
      },
      handleShowPayment() {
        if (this.newInvoice.user.id === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.seasonSchedules.validators.user'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        } else if (this.data.length === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.seasonSchedules.validators.noTime'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        } else {
          this.sendData()
        }
      },
      handleNewSaleAddonInserted(list) {
        this.data[this.selectedIndex].saleAddonsList = []
        let totalPrice = 0
        if (list.length > 0) {
          list.forEach(item => {
            let row = JSON.parse(JSON.stringify(this.data[this.selectedIndex]))
            row.saleAddonsList.push(item)
            this.data[this.selectedIndex] = row
            totalPrice += parseInt(item.finalPrice.value.split(',').join(''))
          })
        }
        let selectedRow = JSON.parse(JSON.stringify(this.data))
        selectedRow[this.selectedIndex].saleAddons = {
          value: totalPrice,
          type: 'price'
        }

        const index = this.activeDiscounts.map(e => e.termId).indexOf(selectedRow[this.selectedIndex].termId)
        if (index > -1) {
          let discount = 0
          if (this.activeDiscounts[index].percent > 0) {
            discount = (parseInt(selectedRow[this.selectedIndex].reservePrice.value.split(',').join('') || 0) + totalPrice) * (this.activeDiscounts[index].percent / 100)
          } else {
            discount = this.activeDiscounts[index].price
          }
          selectedRow[this.selectedIndex].discount = addComma(discount)
        }
        this.data = selectedRow
        this.selectSaleAddonPromptStatus = false
      },
      handleDeleteRow(row) {
        if (row) {
          const index = this.data.map((elm) => {
            return elm.id
          }).indexOf(row.id)
          if (index > -1 && row.id !== 0 && this.data.length > 1) {
            this.selectedIndex = 0

            for (let i = this.reserveGeneratedInfo.schedules.length - 1; i >= 0; i--) {
              if (this.reserveGeneratedInfo.schedules[i].court_id === row.courtId) {
                this.reserveGeneratedInfo.schedules.splice(i, 1)
              }
            }

            this.data.splice(index, 1)
            this.selectedOrders.splice(index, 1)
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('sales.seasonSchedules.validators.emptyTime'),
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400
            })
          }
        }
      },
      handleClick(id) {
        document.getElementById(id).click()
      },
      sendData() {
        let errorNum = 0

        if (this.insertInvoiceRequestSent) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            color: 'warning',
            time: 5000,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (errorNum === 0) {
          // this.reserveGeneratedInfo.discount = parseInt(this.newInvoice.totalDiscount.value)

          this.insertInvoiceRequestSent = true

          insertSeasonSchedule(this.reserveGeneratedInfo).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('sales.seasonSchedules.notifications.insert.success'),
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather',
              time: 2400
            })
            this.$router.replace({name: 'customerSeasonSchedulesList', params: {userId: this.$route.query.user}})
          }).catch(error => {
            this.insertInvoiceRequestSent = false
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              const error_mapper = {
                'date': this.$t('sales.seasonSchedules.notifications.parseError.date'),
              }
              switch (error.response.status) {
                case 409:
                  // if (error.response.data && error.response.data.data && error.response.data.data.hasOwnProperty('user_debt')) {
                  const newInvoice = error.response.data

                  if (newInvoice.error_type === 'balance_error') {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: this.$t('sales.seasonSchedules.notifications.lowMoney'),
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })

                    this.userDebt = Math.abs(newInvoice.money_deficit)
                    // this.newInvoice.id.value = newInvoice.id
                    this.receiveFromCustomerPromptStatus = true
                  } else {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: this.$t('sales.seasonSchedules.notifications.timesReserved'),
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                  }
                  break

                case 422:
                  Object.keys(error.response.data.errors).forEach((error_key) => {
                    const err = error_key.toString().split('.')
                    if (error_mapper[err[err.length - 1]]) {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: error_mapper[err[err.length - 1]],
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      error_mapper[err[err.length - 1]] = null
                    }
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('sales.seasonSchedules.notifications.insert.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                  break
              }
            }
          })
        }
      },
      routeToInvoice() {
        // window.open(this.$router.resolve({name: 'print-sale-invoice-page', params:{id: this.newInvoice.id.value}}).href, '_blank')
        this.$router.replace({name: 'customerSeasonSchedulesList', params: {userId: this.$route.query.user}})
      },
      handleInsertSaleAddon(row) {
      },
      handleOpenSelectSaleAddonPrompt(index) {
        if (this.newInvoice.user.id > 0) {
          if (this.getSaleAddonStatus) {
            this.selectSaleAddonPromptStatus = true
            this.selectedIndex = index
          } else {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              color: 'warning',
              time: 5000,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.seasonSchedules.validators.user'),
            color: 'danger',
            time: 5000,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      }
    },
    watch: {
      'newInvoice': {
        handler() {
          clearTimeout(this.firstVisitTimer)
          this.firstVisitTimer = setTimeout(() => {
            this.firstVisit = false
          }, 500)

          /*if (!this.firstVisit) {
            this.$store.dispatch('setPageChanges')
          }*/
        },
        deep: true
      },
      '$store.state.helper.documentsChanged': {
        handler(val) {
          if (val) {
            this.sendData()
          }
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss">
  .insert-season-sale-invoice {
    height: 100%;

    .general-fields, .products-fields {
      padding: .5rem;
    }

    .products-fields {
      height: calc(100% - 86px);
    }

    .general-fields {
      display: flex;
      flex-wrap: wrap;
      width: unset;
      margin: 0.5rem;
      background: #cecece22;
      border: 1px solid #cecece;
      border-radius: 0.5rem;
      padding-bottom: 0;

      div.vs-col {
        position: relative;
      }

      .custom-validate-input label {
        background-color: #ffffff;
      }

      .custom-validate-input label span.is-focus,
      .custom-number-input label span.is-focus,
      span.custom-select-label {
        backdrop-filter: sepia(1);
        background: linear-gradient(180deg, #f8f8f8, #ffffff);
      }

      span.custom-select-label {
        font-size: 12px;
        position: absolute;
        padding: 0 5px;
        left: 5px;
        top: 5px;
        z-index: 201;
      }
    }

    .select-user-invoice, .select-user-address-invoice {
      position: relative;

      .suggest-modal-button, .new-suggest-modal-button {
        position: absolute;
        bottom: 0;
        right: 42px;
        top: 20px;
        width: 25px;
        height: 25px;
        padding: 5px;
        border: 1px solid #00bcd4;
        border-radius: .5rem;
        color: #00bcd4;
        background: white;
        cursor: pointer;
      }

      .new-suggest-modal-button {
        right: 12px;
        border: 1px solid #28C76F;
        color: #28C76F;
      }

      .suggest-box {

        ul {
          margin-top: -10px;
          border-radius: .5rem;
          padding: 0 10px;
          line-height: 35px;
          background: white;
          box-shadow: 0 5px 10px -8px;
          border: 1px solid #cecece;
          right: .5rem;
          /*left: .5rem;*/

          &::-webkit-scrollbar {
            display: none;
          }

          li {
            cursor: pointer;
            border-top: 1px solid #cecece;

            &:first-child {
              border-top: none !important;
            }
          }
        }
      }
    }

    table {
      border: 1px solid #1b1b1c;
      border-radius: 5px;

      tr {

        div.select-suggest-list {
          right: 0 !important;
          left: 0 !important;
        }
      }
    }


    .draggable-dynamic-table {
      .new-suggest-modal-button {
        display: none;
      }

      .suggest-modal-button {
        right: 7px !important;
      }
    }
  }

  .action-buttons {
    display: flex;

    button {
      flex: 1;
      line-height: 40px;
      border-radius: .5rem;
      border: none;
      color: #ffffff;
      transition: all .3s ease;
      cursor: pointer;

      &.payment {
        margin-right: 10px;
        background: #33b000cf;

        &:hover {
          background: #33b000;
        }
      }

      &.cancel {
        background: #b00000af;

        &:hover {
          background: #b00000;
        }
      }
    }
  }
</style>
