<template>
  <vx-card class="insert-season-sale-invoice main-box top-zero-radius">

    <div class="date-items">
      <div class="date-items">
        <div class="date-content">
          <template v-for="(court, key) in courtsList">
            <button class="date-btn"
                    :class="selectedTableCourt > -1 && key === selectedTableCourt || selectedTableCourt === -1 && key - 1 === weekIndex ? 'active' : ''"
                    @click="setTableCourt(key)">
              {{ court.name }}
              <span v-if="tableDayReserveCount[key] > 0" class="day-reserve-count">
                {{ tableDayReserveCount[key] }}
              </span>
            </button>
          </template>

        </div>
      </div>
    </div>

    <div class="time-table" :class="isMobile ? 'mobile' : ''">
      <div class="time-table-body">
        <div v-if="timesList.length > 0"
             class="court-row time-label">
          <div class="court-name">{{ $t('sales.seasonSchedules.labels.timeWeek') }}</div>
          <select-time v-for="(item, time_index) in timesList[0].times"
                       :time="item.time"
                       :court="item.court"
                       :price="0"
                       :term-id="item.term_id"
                       :is-label="true"
                       :disabled="true"
                       :selected-times="selectedTimes"/>
        </div>
        <div v-if="courtItem.times && courtItem.times.length > 0"
             class="court-row"
             v-for="(courtItem, court_index) in timesList"
             :key="`court_${court_index}`">
          <div class="court-name">{{ courtItem ? courtItem.name : '' }}</div>
          <select-time v-for="(item, time_index) in courtItem.times"
                       :time="item.time"
                       :court="item.court"
                       :price="item.price"
                       :term-id="item.term_id"
                       :current-time="getCurrentHour(item)"
                       :date="item.week_day"
                       :status="item.status === 0 || item.status === 2 ? 3 : item.status"
                       :disabled="getTableItemDisabled(item)"
                       :type="item.type"
                       :selected-times="selectedTimes"
                       @time:selected="handleTimeSelected($event)"
                       @time:unselected="handleTimeUnselected($event)"
                       @disableClicked="handleShowDisableReason"/>
        </div>
      </div>
    </div>


    <!-- show selected times prompt -->
    <vs-prompt
        class="big-prompt p-0"
        :buttons-hidden="true"
        title=""
        :active.sync="confirmTimesPromptStatus"
        @close="confirmTimesPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('showSeasonPaymentModal')">
              <custom-icon icon="SAVE_PAY" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.seasonSchedules.labels.confirm') }}
            {{ newInvoice.user.name.value }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="confirmTimesPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-season-schedule-invoice :user-info="newInvoice.user"
                                            :reserve-info="reserveInfo"
                                            :selected-times="calculatedTimes"
                                            :courts-list="courtsList"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- show disable time invoices prompt -->
    <vs-prompt
        class="big-prompt p-0"
        :buttons-hidden="true"
        title=""
        :active.sync="showDisableTimeInvoicesPromptStatus"
        @close="showDisableTimeInvoicesPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
<!--            <div @click="">-->
<!--              <custom-icon icon="SAVE_PAY" color="success"/>-->
<!--            </div>-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.seasonSchedules.disableTimes.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showDisableTimeInvoicesPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <disable-time-invoices-list :time-info="selectedDisableTime"
                                        :start-date="newInvoice.startDate.value"
                                        :end-date="newInvoice.endDate.value"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>


    <!-- show date range prompt -->
    <vs-prompt
        class="prompt p-0"
        :buttons-hidden="true"
        title=""
        :active.sync="selectInvoiceDateRangePromptStatus"
        @close="handleCloseSelectDateRangeModal">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleSetTimesRange">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.seasonSchedules.labels.selectDateRange') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
<!--            <div @click="handleSetTimesRange">-->
<!--              <custom-icon icon="TIMES-CIRCLE" color="danger"/>-->
<!--            </div>-->
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <div class="select-date-range">
              <div class="select-date-input">
                <custom-date-picker-input :label="$t('sales.seasonSchedules.labels.startDate')"
                                          type="date"
                                          :regex="$validator('regex.date')"
                                          :input-format="$validator('moment.date')"
                                          :auto-submit="true"
                                          :min="getDateRageMin"
                                          v-model="startDate" />
              </div>
              <div class="select-date-input">
                <custom-date-picker-input :label="$t('sales.seasonSchedules.labels.endDate')"
                                          type="date"
                                          :regex="$validator('regex.date')"
                                          :input-format="$validator('moment.date')"
                                          :placeholder="getEndDatePlaceHolder"
                                          :auto-submit="true"
                                          :min="getEndDateRageMin"
                                          v-model="endDate" />
              </div>
            </div>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <vs-button id="setDateRange" class="useral-action-button" @click="selectInvoiceDateRangePromptStatus = true"/>
    <vs-button id="setTimeSelected" class="useral-action-button" @click="handleShowReserveConfirm"/>
  </vx-card>
</template>

<script>
import moment from "moment-jalaali";
import axios from "axios";
import {getTimes} from "../../../../../http/requests/times";
import {getCourts} from "../../../../../http/requests/courts";
import {getReservableTimes} from "../../../../../http/requests/sales";
import SelectTime from "../../../../../components/reserveTime/selectTime";
import CustomIcon from "../../../../../components/customIcon/customIcon";
import {showLoading, hideLoading, getTimeFromServer} from "../../../../../assets/js/functions";
import CustomDatePickerInput from "../../../../../components/customInput/customDatePickerInput";
import {getUser} from "../../../../../http/requests/users/users";
import DisableTimeInvoicesList from "./disableTimeInvoicesList";
import InsertSeasonScheduleInvoice from "./insertSeasonScheduleInvoice";
import {seasonScheduleCalculator} from "../../../../../http/requests/seasonSchedules";

export default {
  name: "insertTimeSeasonScheduleInvoice",
  components: {
    InsertSeasonScheduleInvoice,
    DisableTimeInvoicesList, CustomDatePickerInput, CustomIcon, SelectTime},
  metaInfo() {
    return {
      title: this.$t('sales.seasonSchedules.insert.title')
    }
  },
  props: {
    multiSelect: {
      type: Boolean,
      default: () => { return true }
    },
    inModal: {
      type: Boolean,
      default: () => { return false }
    }
  },
  data() {
    return {
      confirmTimesPromptStatus: false,
      selectInvoiceDateRangePromptStatus: true,
      showDisableTimeInvoicesPromptStatus: false,
      futureWeeks: 0,
      weekIndex: 0,
      currentTime: moment(getTimeFromServer()).format(this.$validator('moment.date')),
      serverTime: '',
      getCurrentHourTimer: 0,
      selectedTableCourt: -1,
      selectedDisableTime: {},
      timesList: [],
      courtsList: [],
      reservedList: [],
      tableDayReserveCount: [],
      selectedTimes: {},
      reserveTypeStatus: 'date',
      startDate: {
        value: '',
        isValid: false
      },
      endDate: {
        value: '',
        isValid: false
      },
      calculatedTimes: [],
      reserveInfo: {},
      newInvoice: {
        id: {
          value: '',
          isValid: true
        },
        actionType: {
          value: 1,
          label: this.$t('sales.invoices.actionTypes.present')
        },
        status: {},
        user: {
          id: 0,
          name: {
            value: '',
            isValid: true
          }
        },
        startDate: {
          value: '',
          isValid: false
        },
        endDate: {
          value: '',
          isValid: false
        },
        date: {
          value: moment(getTimeFromServer()).format(this.$validator('moment.date')),
          isValid: true
        },
        finalPrice: 0,
        totalPrice: 0,
      },
      invoiceProcesses: [
        {
          label: this.$t('sales.invoices.processTypes.preInvoice'),
          value: 1
        },
        {
          label: this.$t('sales.invoices.processTypes.invoice'),
          value: 2
        }
      ],
      actions: [
        {
          toolbar: [
            // {
            //   id: 'setTimeSelected',
            //   icon: 'CHECK',
            //   iconPack: 'useral',
            //   color: 'success',
            //   badge: {
            //     value: 0
            //   }
            // }
          ],
          leftToolbar: [
            {
              id: 'setDateRange',
              icon: 'CALENDER',
              iconPack: 'useral',
              i18n: ''
            },
            {
              id: {name: 'customerSeasonSchedulesList', params: {userId: this.$route.query.user}},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.windowWidth <= this.$store.state.mobileScreen
    },

    getDateRageMin () {
      return moment(this.serverTime).add(1, 'day').format(this.$validator('moment.date'))
    },

    getEndDatePlaceHolder () {
      return moment(this.currentTime, this.$validator('moment.date')).add(1, 'week').format(this.$validator('moment.date'))
    },

    getEndDateRageMin () {
      if (this.startDate.value && moment(this.startDate.value, this.$validator('moment.date')).isValid()) {
        return moment(this.startDate.value, this.$validator('moment.date')).add(1, 'week').format(this.$validator('moment.date'))
      } else {
        return moment(this.serverTime).add(1, 'week').format(this.$validator('moment.date'))
      }
    }
  },
  created() {
    if (!this.inModal) {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.newInvoice.status = this.invoiceProcesses[0]
      }, 100)
    }
    this.serverTime = getTimeFromServer()
    this.currentTime = moment(this.serverTime).format(this.$validator('moment.date'))
    this.startDate = {
      value: moment(this.currentTime, this.$validator('moment.date')).add(1, 'day').format(this.$validator('moment.date')),
      isValid: true
    }

    this.weekIndex = (moment(this.currentTime).day() + 1) % 7
    this.selectedTableCourt = 0

    this.getTimes()
    this.getCourts()
    this.getUserDefault()
  },
  mounted () {
    this.getCurrentHourTimer = setTimeout(() => {
      this.getCurrentHourTimer = 0
    }, 60000 - parseInt(moment(this.currentTime).format(this.$validator('moment.second'))) * 1000)
  },
  methods: {
    getCurrentHour (item) {
      return moment(this.currentTime).format(this.$validator('moment.dateHour')) === `${item.date} ${item.time.start_time.split(':')[0]}`
    },
    getUserDefault () {
      this.newInvoice.status = this.invoiceProcesses[0]
      this.newInvoice.actionType = this.actionType

      if (this.$route.query.user) {
        this.newInvoice.user.id = this.$route.query.user
        getUser(this.newInvoice.user.id).then(response => {
          const user = response.data.data
          this.newInvoice.user.name.value = `${user.name || ''} ${user.family || ''}`
          this.$store.dispatch('setPageTitle', this.$t('sales.seasonSchedules.insert.dynamicTitle', {gender: user.gender === 1 ? this.$t('genderTypes.dynamicMan') : this.$t('genderTypes.woman'), name: this.newInvoice.user.name.value}))
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      }
    },
    getDateBtnDisabled (i) {
      if (this.$route.name === 'insertPastSaleInvoice') {
        return this.futureWeeks === 0 && i - 1 > this.weekIndex
      } else {
        return this.futureWeeks === 0 && i - 1 < this.weekIndex
      }
    },
    getTableItemDisabled (item) {
      if (this.$route.name === 'insertPastSaleInvoice') {
        return parseInt(item.price) === 0
      } else {
        return item.status !== 1
      }
    },
    setTableCourt(index = this.selectedTableCourt) {
      this.selectedTableCourt = index
      this.timesList = []
      this.getReservableTimes()
    },
    getReservableTimes() {
      switch (this.reserveTypeStatus) {
        case 'date':
          this.getReservableTimesBaseDate()
          break
      }
    },
    getReservableTimesBaseDate() {
      showLoading()
      let filters = ['type=3', `start_range=${this.startDate.value}`, `end_range=${this.endDate.value}`, `court_id=${this.courtsList[this.selectedTableCourt].id}`]
      getReservableTimes(1, filters).then((response) => {
        const data = response.data.data
        let totalTimes = {}
        data.forEach(time => {
          if (!totalTimes[`weekday_${time.week_day}`]) {
            let court = time.court
            court.times = []
            totalTimes[`weekday_${time.week_day}`] = {
              id: time.week_day,
              date: time.week_day,
              name: this.$validator(`moment.weekDay.${time.week_day}`),
              times: []
            }
          }
          switch (time.status) {
            case 1:
              time.type = 'selectable'
              break

            default:
              time.type = 'disable'
              break
          }
          totalTimes[`weekday_${time.week_day}`].times.push(JSON.parse(JSON.stringify(time)))
        })
        this.timesList = Object.values(totalTimes)

        this.newInvoice.startDate = this.startDate
        this.newInvoice.endDate = this.endDate

        const actions = JSON.parse(JSON.stringify(this.actions[0]))
        const calendarIndex = actions.leftToolbar.map(e => e.id).indexOf('setDateRange')
        actions.leftToolbar[calendarIndex].i18n = `${this.newInvoice.startDate.value} ${this.$t('sales.seasonSchedules.labels.until')} ${this.newInvoice.endDate.value}`

        setTimeout(() => {
          this.$store.dispatch('updateNavbarActions', actions)
        }, 50)

        hideLoading()
      })
        .catch(error => {
          hideLoading()

          this.selectInvoiceDateRangePromptStatus = true

          const error_mapper = {
            'start_range': this.$t('sales.seasonSchedules.notifications.parseError.startDate'),
            'end_range': this.$t('sales.seasonSchedules.notifications.parseError.endDate'),
          }

          switch (error.response.status) {
            case 422:
              Object.keys(error.response.data.errors).forEach((error_key) => {
                const err = error_key.toString().split('.')
                if (error_mapper[err[err.length - 1]]) {
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: error_mapper[err[err.length - 1]],
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 5000
                  })
                  error_mapper[err[err.length - 1]] = null
                }
              })
              break

            default:
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('sales.seasonSchedules.notifications.parseError.getTimes'),
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400
              })
              break
          }
        })
    },
    getTimes() {
      getTimes().then(response => {
        const times = response.data.data
        times.forEach((time) => {
          this.timesList.push({
            id: time.id,
            startTime: time.start_time.substr(0, 5),
            endTime: time.end_time.substr(0, 5)
          })
        })
      })
    },
    getCourts() {
      getCourts().then((response) => {
        const courts = response.data.data
        courts.forEach((court) => {
          this.courtsList.push({
            id: court.id,
            name: court.name
          })
        })
      })
    },
    handleShowReserveConfirm () {
      if (Object.values(this.selectedTimes).length > 0) {
        let times = []
        Object.values(this.selectedTimes).forEach(item => {
          times.push({
            time_id: item.time.id,
            term_id: item.termId,
            court_id: item.court.id,
            week_day: item.date
          })
        })

        this.reserveInfo = {
          started_at: this.newInvoice.startDate.value,
          ended_at: this.newInvoice.endDate.value,
          user_id: this.$route.query.user,
          schedules: times
        }

        showLoading()

        seasonScheduleCalculator(this.reserveInfo).then(response => {
          hideLoading()

          this.calculatedTimes = response.data.times
          this.confirmTimesPromptStatus = true
        }).catch(() => {
          hideLoading()
        })
      } else {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('sales.seasonSchedules.validators.noTime'),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400
        })
      }
    },
    handleTimeSelected(selectedTime) {
      delete selectedTime.court.times
      this.selectedTimes[`${selectedTime.date}_${selectedTime.time.id}_${selectedTime.court.id}`] = selectedTime
      let actions = JSON.parse(JSON.stringify(this.actions[0]))
      if (Object.values(this.selectedTimes).length > 0) {
        actions.toolbar.push({
          id: 'setTimeSelected',
          icon: 'CHECK',
          iconPack: 'useral',
          color: 'success',
          badge: {
            value: 0
          },
          permission: 'invoice.create'
        })
      }
      const calendarIndex = actions.leftToolbar.map(e => e.id).indexOf('setDateRange')
      actions.leftToolbar[calendarIndex].i18n = `${this.newInvoice.startDate.value} ${this.$t('sales.seasonSchedules.labels.until')} ${this.newInvoice.endDate.value}`

      if (this.multiSelect) {
        if (actions.toolbar.length > 0) {
          actions.toolbar[0].badge.value = Object.values(this.selectedTimes).length
        }
        setTimeout(() => {
          this.$store.dispatch('updateNavbarActions', actions)
        }, 100)
        this.getReserveTableCount()
      } else {
        this.$emit('selected', this.selectedTimes)
      }
    },
    getReserveTableCount () {
      const selectedTimes = Object.keys(this.selectedTimes)
      let timeCounts = []
      this.courtsList.forEach(court => {
        let count = 0
        let date = court.id
        selectedTimes.forEach((time) => {
          if (parseInt(time.split('_')[2]) === date) {
            count++
          }
        })
        timeCounts.push(count)
      })
      this.tableDayReserveCount = timeCounts
    },
    handleTimeUnselected(unselectedTime) {
      delete this.selectedTimes[`${unselectedTime.date}_${unselectedTime.time.id}_${unselectedTime.court.id}`]
      setTimeout(() => {
        let actions = JSON.parse(JSON.stringify(this.actions[0]))
        if (Object.values(this.selectedTimes).length === 0) {
          this.actions[0].toolbar.splice(0, 1)
          actions.toolbar.splice(0, 1)
        } else {
          actions.toolbar.push({
            id: 'setTimeSelected',
            icon: 'CHECK',
            iconPack: 'useral',
            color: 'success',
            badge: {
              value: 0
            },
            permission: 'invoice.create'
          })
          actions.toolbar[0].badge.value = Object.values(this.selectedTimes).length
        }

        const calendarIndex = actions.leftToolbar.map(e => e.id).indexOf('setDateRange')
        actions.leftToolbar[calendarIndex].i18n = `${this.newInvoice.startDate.value} ${this.$t('sales.seasonSchedules.labels.until')} ${this.newInvoice.endDate.value}`

        this.$store.dispatch('updateNavbarActions', actions)
      }, 100)
      this.getReserveTableCount()
    },
    handleSetTimesRange () {
      if (!this.startDate.isValid && moment(this.startDate.value, this.$validator('moment.date')).isValid()) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('sales.seasonSchedules.notifications.startDate'),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400
        })
        return false
      }
      if (!this.endDate.isValid && moment(this.endDate.value, this.$validator('moment.date')).isValid()) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('sales.seasonSchedules.notifications.endDate'),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400
        })
        return false
      }

      if (!moment(this.endDate.value, this.$validator('moment.date')).isAfter(moment(this.startDate.value, this.$validator('moment.date')))) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('sales.seasonSchedules.notifications.seasonDateRange'),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400
        })
        return false
      }

      if (moment(this.endDate.value, this.$validator('moment.date')).diff(moment(this.startDate.value, this.$validator('moment.date')), 'days') < 6) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('sales.seasonSchedules.notifications.mustGTEWeek'),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400
        })
        return false
      }

      showLoading()
      this.selectedTimes = {}
      this.timesList = []
      this.selectInvoiceDateRangePromptStatus = false
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.getReservableTimes()
      this.getReserveTableCount()
    },

    handleShowDisableReason (time) {
      this.selectedDisableTime = time
      this.showDisableTimeInvoicesPromptStatus = true
    },
    handleClick(id) {
      document.getElementById(id).click()
    },
    handleCloseSelectDateRangeModal () {
      if (this.newInvoice.startDate.value === '' && this.newInvoice.endDate.value === '') {
        this.$router.replace({name: 'customerSeasonSchedulesList', params: {userId: this.$route.query.user}})
      } else {
        this.startDate = this.newInvoice.startDate
        this.endDate = this.newInvoice.endDate
        this.selectInvoiceDateRangePromptStatus = false
      }
    }
  },

  watch: {
    getCurrentHourTimer: {
      handler (val) {
        if (val === 0) {
          this.$forceUpdate()
          this.getCurrentHourTimer = setTimeout(() => {
            this.getCurrentHourTimer = 0
          }, 60000)
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../../../assets/scss/vuexy/variables";

.insert-season-sale-invoice {

  .vx-card__body {
    padding: 0 !important;
  }

  .date-items {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;

    .prev-date-btn,
    .next-date-btn {
      padding: 5px 20px;
      margin: 10px;
      background-color: #f8f8f8;
      border: 1px solid #cecece;
      border-radius: 0.5rem;

      &:hover:not([disabled]) {
        cursor: pointer;
        background-color: #f0f0f0;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .date-items {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      overflow: auto;
      .date-content {
        display: flex;
        text-align: center;
        overflow-x: auto;
        padding: 5px;
        padding-top: 10px;

        &::-webkit-scrollbar {
          display: block;
          background: rgba(255, 255, 255, 0.50);
          height: 10px;
          width: 10px;
          border-radius: 0.5rem;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          background: rgba(206, 206, 206, 1);
          height: 6px;
          border-radius: .5rem;
        }

        .date-btn {
          position: relative;
          padding: 5px 20px;
          margin: 0 5px;
          background-color: #f8f8f8;
          border: 1px solid #cecece;
          border-radius: 0.5rem;
          white-space: nowrap;

          &:hover:not([disabled]) {
            cursor: pointer;
            background-color: #f0f0f0;
          }

          &.active {
            background-color: $sidebar-semi-bg;
            color: #ffffff;

            &:hover {
              background-color: #1b2027;
            }
          }

          &:disabled {
            cursor: not-allowed;
          }

          .day-reserve-count {
            position: absolute;
            top: -10px;
            right: calc(50% - 8px);
            height: 16px;
            width: 16px;
            background-color: $success;
            color: #ffffff;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .time-table {
    position: relative;
    height: calc(100vh - 225px);
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*align-content: start;*/
    /*flex-wrap: wrap;*/
    padding: 10px;
    /*padding-left: 170px;*/
    align-self: flex-start;
    justify-self: flex-start;
    direction: ltr;



    &::-webkit-scrollbar {
      display: block;
      background: rgba(255, 255, 255, 0.50);
      height: 10px;
      width: 10px;
      border-radius: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      display: block;
      background: rgba(206, 206, 206, 1);
      height: 6px;
      border-radius: .5rem;
    }

    &.mobile {
      height: calc(100vh - 175px);
    }

    .time-table-body {
      display: flex;
      -webkit-box-orient: revert;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row;
      flex-direction: row;
      position: relative;
      align-self: flex-start;
      justify-self: flex-start;
      margin: auto;
    }

    .court-row {
      position: relative;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      width: calc(100vw / 7);
      min-width: 80px;
      max-width: 150px;

      @media screen and (max-width: 768px) {
        width: 100px;
      }

      .court-name {
        width: 100%;
        line-height: 60px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $sidebar-semi-bg;
        /*border-radius: 0.5rem 0.5rem 0 0;*/
        position: relative;
        align-self: flex-start;
        color: #ffffff;
        font-weight: 500;
        border-right: 1px solid #cdcdcd;
        border-bottom: 1px solid #cdcdcd;
        z-index: 90;


        @supports (position: sticky) {
          position: -webkit-sticky;
          position: sticky;
          top: -11px;
        }
      }

      &:first-child {
        .court-name {
          border-radius: 0.5rem 0 0 0;
        }
      }

      &:last-child {
        .court-name {
          border-radius: 0 0.5rem 0 0;
          border-right: none;
        }

        .select-hour-box:last-child {
          button {
            border-radius: 0 0 0.5rem 0;
          }
        }
      }
    }

    .time-label {
      position: relative;
      /*position: -webkit-sticky;*/
      /*background-color: #f8f8f8;*/
      /*align-self: flex-start;*/
      /*justify-self: flex-start;*/
      z-index: 100;
      width: 90px;

      @supports (position: sticky) {
        position: -webkit-sticky;
        position: sticky;
        left: -10px;
      }

      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        position: -webkit-sticky;
        position: sticky;
        left: 0;
      }

      .court-name {
        z-index: 101;
      }

      .select-hour-box {
        position: relative;
        height: 60px;
        left: 0;
        right: 0;

        .is-label {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $sidebar-semi-bg;
          color: #ffffff;
          z-index: 100;
        }
      }

      .select-hour-box:last-child {
        .is-label {
          border-radius: 0 0 0 0.5rem;
        }
      }
    }
  }
}

.action-buttons {
  display: flex;

  button {
    flex: 1;
    line-height: 40px;
    border-radius: .5rem;
    border: none;
    color: #ffffff;
    transition: all .3s ease;
    cursor: pointer;

    &.payment {
      margin-right: 10px;
      background: #33b000cf;

      &:hover {
        background: #33b000;
      }
    }

    &.cancel {
      background: #b00000af;

      &:hover {
        background: #b00000;
      }
    }
  }
}
</style>
