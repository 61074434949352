import axios from '@/http/axios'

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getSeasonSchedules (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/season-schedules${queries}`,
    method: 'get'
  })
}

export function getSeasonSchedule (id) {
  return axios({
    url: `v1/admin/season-schedules/${id}`,
    method: 'get'
  })
}

export function getSeasonScheduleInvoices (id, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/season-schedules/${id}/sale-invoices${queries}`,
    method: 'get'
  })
}

export function getSeasonSchedulePrice (id) {
  return axios({
    url: `v1/admin/season-schedules/${id}/total-sale-invoices`,
    method: 'get'
  })
}

export function seasonScheduleCalculator (payload) {
  return axios({
    url: 'v1/admin/season-schedule-calculator',
    data: payload,
    method: 'post'
  })
}

export function insertSeasonSchedule (payload) {

  return axios({
    url: 'v1/admin/season-schedules',
    data: payload,
    method: 'post'
  })
}

export function deleteSeasonSchedule (id, force = false) {

  return axios({
    url: `v1/admin/season-schedules/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}
